/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'utQWPPnxopcthUo811LD2H.png': { uri: '/images/utQWPPnxopcthUo811LD2H.png' },
'csrnUov8vkAWGWZVYVz6cR.png': { uri: '/images/csrnUov8vkAWGWZVYVz6cR.png' },
'n5iQkGKRS9TCCXXxC96hkB.png': { uri: '/images/n5iQkGKRS9TCCXXxC96hkB.png' },
'rTszJ8QL9qeLQyXa9455tu.png': { uri: '/images/rTszJ8QL9qeLQyXa9455tu.png' },
'i3SDkvZhAyMd9ioWR3HGU9.png': { uri: '/images/i3SDkvZhAyMd9ioWR3HGU9.png' },
'cCS2NZwst6ubL4X95hu4kP.png': { uri: '/images/cCS2NZwst6ubL4X95hu4kP.png' },
'ecPDn6gUERq6G7xRxTiVnf.png': { uri: '/images/ecPDn6gUERq6G7xRxTiVnf.png' },
'jTfvgvMgLoQXkfs82Ns2f5.png': { uri: '/images/jTfvgvMgLoQXkfs82Ns2f5.png' },
'bF5KihRaD9QaMX3CKnj7tM.png': { uri: '/images/bF5KihRaD9QaMX3CKnj7tM.png' },
'd2oPWGEiw3bwDWWUCkGwHy.png': { uri: '/images/d2oPWGEiw3bwDWWUCkGwHy.png' },
'jhZqVEVh9a7gcfeedVPd1P.png': { uri: '/images/jhZqVEVh9a7gcfeedVPd1P.png' },
'qiEDqA5vsByYZVJxKPK6Jr.png': { uri: '/images/qiEDqA5vsByYZVJxKPK6Jr.png' },
'vTjgdmYxN3KgEV3eYMKFkD.png': { uri: '/images/vTjgdmYxN3KgEV3eYMKFkD.png' },
'udiknixEjGvfBjstp1DFSa.png': { uri: '/images/udiknixEjGvfBjstp1DFSa.png' },
'spg2Uuc1CcakPGVP7xbHR8.png': { uri: '/images/spg2Uuc1CcakPGVP7xbHR8.png' },
'gi9tRJV84bYMddcaRQhYuQ.png': { uri: '/images/gi9tRJV84bYMddcaRQhYuQ.png' },
'7t9LaZXHqJU7LZ8sUJiJUn.png': { uri: '/images/7t9LaZXHqJU7LZ8sUJiJUn.png' },
'tPqfU95nPPLxS8FoMToftm.png': { uri: '/images/tPqfU95nPPLxS8FoMToftm.png' },
'vGLPbpj45CPURtr77jAGgt.png': { uri: '/images/vGLPbpj45CPURtr77jAGgt.png' },
'b9zXaLeARDv8AXkM11yMrX.png': { uri: '/images/b9zXaLeARDv8AXkM11yMrX.png' },
'oWWkwc8EVCZYzxGSvH1hYj.png': { uri: '/images/oWWkwc8EVCZYzxGSvH1hYj.png' },
'8W1DcgxrjRNj2Uwh1icy31.png': { uri: '/images/8W1DcgxrjRNj2Uwh1icy31.png' },
'f15iHufTcS8XHYjXTq8mHc.png': { uri: '/images/f15iHufTcS8XHYjXTq8mHc.png' },
'x82USn3ApAXCVKGY5yHGZR.png': { uri: '/images/x82USn3ApAXCVKGY5yHGZR.png' },
'4rqXaAnjz7QJ5QPjHMeLyQ.png': { uri: '/images/4rqXaAnjz7QJ5QPjHMeLyQ.png' },
'39Yje5geZViDhZofhbTkNE.png': { uri: '/images/39Yje5geZViDhZofhbTkNE.png' },
'tXMqWcmxfhCemjwsjFeyFo.png': { uri: '/images/tXMqWcmxfhCemjwsjFeyFo.png' },
'cMYAhDpZMuPsoe9mm6AHxr.png': { uri: '/images/cMYAhDpZMuPsoe9mm6AHxr.png' },
'kzCKp6c9ChHWyp2qZ1y7Bf.png': { uri: '/images/kzCKp6c9ChHWyp2qZ1y7Bf.png' },
'2WmkXiNRWS2m7mR2kz3FAK.png': { uri: '/images/2WmkXiNRWS2m7mR2kz3FAK.png' },
'2YyuruWD2grqR6VQjFi59e.png': { uri: '/images/2YyuruWD2grqR6VQjFi59e.png' },
'3TaU62Q9f9K5jvaapzqLqi.png': { uri: '/images/3TaU62Q9f9K5jvaapzqLqi.png' },
'3iSLVWCsoJ8ApwLwEB6Ynm.png': { uri: '/images/3iSLVWCsoJ8ApwLwEB6Ynm.png' },
'n3RhcuzgfX1U7y2Wh8BJRE.png': { uri: '/images/n3RhcuzgfX1U7y2Wh8BJRE.png' },
'9yeR6Tgyh8zFRStKneEW2H.png': { uri: '/images/9yeR6Tgyh8zFRStKneEW2H.png' },
'9PwNAJEnLTRjRej2boAkyc.png': { uri: '/images/9PwNAJEnLTRjRej2boAkyc.png' },
'mcPpUSb2Rs1DAMrtij8vSv.png': { uri: '/images/mcPpUSb2Rs1DAMrtij8vSv.png' },
'iKuhMLKQ3aRVWyczRMPBEQ.png': { uri: '/images/iKuhMLKQ3aRVWyczRMPBEQ.png' },
'uZLzo4k7QgxYzZuXtTcY2m.png': { uri: '/images/uZLzo4k7QgxYzZuXtTcY2m.png' },
'kaU1VwfgnQ2GNmaQ2P6qdz.png': { uri: '/images/kaU1VwfgnQ2GNmaQ2P6qdz.png' },
'1rtCwyWQTpqvx2wkRFRhVH.png': { uri: '/images/1rtCwyWQTpqvx2wkRFRhVH.png' },
'wD7a6zoBkhPpbDwt9Rf7UU.png': { uri: '/images/wD7a6zoBkhPpbDwt9Rf7UU.png' },
'6WuJ7e5fsZkGhas1VtxXqE.png': { uri: '/images/6WuJ7e5fsZkGhas1VtxXqE.png' },
'qjmRPqpg5ymkhfRtJA86fm.png': { uri: '/images/qjmRPqpg5ymkhfRtJA86fm.png' },
't66bRPQ2CN8CVMJ4pEentw.png': { uri: '/images/t66bRPQ2CN8CVMJ4pEentw.png' },
'5nkrJkUysdyG53THqehThK.png': { uri: '/images/5nkrJkUysdyG53THqehThK.png' },
'6GdfZCakjXoN1fSvHxf1HP.png': { uri: '/images/6GdfZCakjXoN1fSvHxf1HP.png' },
'4jdZJ1yuGkFbonCNNQVNkL.png': { uri: '/images/4jdZJ1yuGkFbonCNNQVNkL.png' },
'54Ru2qgQQ74pXdxcybCBDi.jpg': { uri: '/images/54Ru2qgQQ74pXdxcybCBDi.jpg' },
'sShN5gXrCFjXKEhN3GZWcA.jpg': { uri: '/images/sShN5gXrCFjXKEhN3GZWcA.jpg' },
'hy6oZ4EqahQDF23f2PbkeS.png': { uri: '/images/hy6oZ4EqahQDF23f2PbkeS.png' },
'7fMYacAAECoypwutVvnJML.png': { uri: '/images/7fMYacAAECoypwutVvnJML.png' }
}

export default imageStaticSourcesByFileName
